import { Image } from '@utmb/design-system/image/Image';
import { SliceCardsCollectionCardView } from '@utmb/types/index';
import { ReactElement } from 'react';
import styles from './cards-collection.module.scss';

export const SlidePhoto = (props: SliceCardsCollectionCardView): ReactElement | null => {
    const { picture } = props;

    if (!picture) return null;

    return (
        <div className={styles.slider_photo_item}>
            <Image layout="fill" objectFit="cover" image={picture} maxWidth={500} />
        </div>
    );
};
