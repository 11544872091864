import { useGlobal } from '@utmb/contexts/GlobalContext';
import { Link } from '@utmb/design-system/link/link';
import { Font } from '@utmb/design-system/text/font';
import { SliceCardsCollectionCardView } from '@utmb/types/index';
import { parseMarkdown } from '@utmb/utils/markdown';
import { ReactElement } from 'react';
import styles from './cards-collection.module.scss';

export const SlideContent = (props: SliceCardsCollectionCardView): ReactElement => {
    const { title, summary, link } = props;

    const {
        global: {
            event: { primaryColorIsDark },
        },
    } = useGlobal();

    return (
        <div className={styles.slider_content_item}>
            {title && <Font.FuturaHeavy className={styles.content_title}>{title}</Font.FuturaHeavy>}
            {summary && (
                <Font.FuturaBook className={styles.content_summary} mobile="14" as="div">
                    <div
                        className={`markdown ${primaryColorIsDark ? 'primaryColorIsDark' : ''}`}
                        dangerouslySetInnerHTML={{ __html: parseMarkdown(summary) }}
                    />
                </Font.FuturaBook>
            )}
            {link && (
                <Font.FuturaBook mobile="14" className={styles.content_link}>
                    <Link className="font-underline -stretched-link" href={link.slug} followClassName={`${link.followClass}-ga`}>
                        {link.label}
                    </Link>
                </Font.FuturaBook>
            )}
        </div>
    );
};
