//  React
import { ReactElement } from 'react';
//  Modules
import classNames from 'classnames/bind';
// Design System
import { Slides, SlidesItem, SlideProps } from '@utmb/design-system/slides/Slides';
// Types
import { Slice } from '@utmb/types/slice-handler';
import { CardsCollectionVariantEnum } from '@utmb/types/enum/CardsCollectionVariantEnum';
import { SliceCardsCollectionView, SliceCardsCollectionCardView } from '@utmb/types/index';
// Styles
import styles from './cards-collection.module.scss';
import { SlidePhoto } from './SlidePhoto';
import { SlideContent } from './SlideContent';

const cx = classNames.bind(styles);

export const CardsCollection = (props: Slice<SliceCardsCollectionView>): ReactElement | null => {
    const { id, cards, cardsCollectionVariant, isDark } = props;

    if (!cards || !cards.length) return null;

    const getVisibleDots = (key: number): string => {
        if (key === 2) {
            return styles.hide_dots_md;
        } else if (key === 3) {
            return styles.hide_dots_xl;
        }
        return styles.hide_all_bp;
    };

    const isVariantCentered: boolean = cardsCollectionVariant === CardsCollectionVariantEnum.CENTERED_PICTURE_BOTTOM;

    const rootCSS = [styles.root].filter(Boolean).join(' ');
    const showSliderDotsCSS = [styles.dots, getVisibleDots(cards.length)].join(' ');

    const show: boolean =
        cardsCollectionVariant === CardsCollectionVariantEnum.LANDSCAPE_PICTURE_TOP ||
        cardsCollectionVariant === CardsCollectionVariantEnum.CENTERED_PICTURE_BOTTOM;

    if (!show) return null;

    const slideProps: SlideProps = {
        className: cx({
            slide_picture_bottom_container: isVariantCentered,
            mg_slide_container: !isVariantCentered,
        }),
        carouselOptions: {
            containScroll: 'trimSnaps',
            align: 'center',
            draggable: true,
            loop: false,
        },
        dotsClassName: showSliderDotsCSS,
    };

    const slideContent: any = (card: SliceCardsCollectionCardView, reverse = false): any => {
        return reverse ? (
            <>
                <SlideContent {...card} />
                <SlidePhoto {...card} />
            </>
        ) : (
            <>
                <SlidePhoto {...card} />
                <SlideContent {...card} />
            </>
        );
    };

    // Because of the custom slide item width (in px/rem), we need to calculate the parent container width to center the slides
    // To be effective, this line has to be combined with slider option `align: true`
    const containerWidth = cards.length * parseInt(styles.slideItemMaxWidth) + (cards.length - 1) * parseInt(styles.slideItemMarginRight);

    return (
        <div id={id} className={rootCSS}>
            <Slides {...slideProps} pagination={true} isDarkPagination={isDark} containerWidth={containerWidth}>
                {() => (
                    <>
                        {cards.map((card: SliceCardsCollectionCardView, index: number) => (
                            <SlidesItem
                                key={index}
                                className={cx({
                                    card_picture_bottom_container: isVariantCentered,
                                    mg_slide_item: !isVariantCentered,
                                    card_bordered: !isDark,
                                })}
                            >
                                {slideContent(card, isVariantCentered)}
                            </SlidesItem>
                        ))}
                    </>
                )}
            </Slides>
        </div>
    );
};
